html, body{
  height: 100vh;
}

body{
    background-color: #000;
}

.info{
  flex-direction: row;
}

@media screen and (max-width: 700px) {
  .info{
    flex-direction: column;
  }
}